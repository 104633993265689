//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "CheatSystemRequirements",
  props: {
    system_requirements: {
      os: String,
      cpu: String,
      gpu: String,
      client: String
    }
  }
}
