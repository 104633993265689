//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const SCROLL_SPEED = 1
import {mapGetters} from 'vuex'

export default {
  name: 'TheCheatCards',
  data() {
    return {
      isScrollLocked: false,
      cardWidth: 270,
      currentTranslateValue: 0,
      lineWidth: 0,
      mainContainerWidth: 0
    }
  },
  mounted() {
    this.setInitialParams()
    this.$refs.mainContainer.addEventListener('resize', () => {
      this.setInitialParams()
    })
  },
  computed: {
    ...mapGetters({cheat_categories: 'getCheatCategories'})
  },
  methods: {
    pullNext() {
      if (!this.isScrollLocked) {
        this.smoothScroll(-2.7)
      }
    },
    pullPrev() {
      if (!this.isScrollLocked) {
        this.smoothScroll(2.7)
      }
    },
    smoothScroll(step) {
      this.isScrollLocked = true
      let counter = 0

      let intervalID = setInterval(() => {
        this.currentTranslateValue += step
        counter++
        if (counter >= 100) {
          clearInterval(intervalID)
          this.isScrollLocked = false
        }
        this.preventGoingAbroad()

        this.$refs.cheatLine.style.transform = 'translateX(' + this.currentTranslateValue + 'px)'
      }, SCROLL_SPEED)
    },
    getMaxPullLeftValue(coefficient = 1) {
      return ((this.cardWidth * this.cheat_categories.length) - this.mainContainerWidth) * -1
    },
    preventGoingAbroad() {
      if (this.currentTranslateValue > 0)
        this.currentTranslateValue = 0

      if (this.currentTranslateValue < this.getMaxPullLeftValue(0.8))
        this.currentTranslateValue = this.getMaxPullLeftValue()
    },
    setInitialParams() {
      this.lineWidth = (this.cheat_categories.length * this.cardWidth)
      this.mainContainerWidth = this.$refs.mainContainer.offsetWidth
      this.currentTranslateValue = 0
      this.$refs.cheatLine.style.transform = 'translateX(0px)'
    }

  },
  SCROLL_SPEED
}
