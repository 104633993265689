//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CheatService from "../../plugins/services/CheatService";

export default {
  name: "TheLastCheats",
  data() {
    return {
      posts: [],
      current_language: this.$i18n.locale
    }
  },
  async fetch() {
    this.posts = await this.$CheatService.getLast()
  },
  methods: {
    getTranslates(post) {
      if (post !== undefined) {
        if (post.hasOwnProperty('translates')) {
          return post.translates
        }
      }

      return []
    },
    getCheatAdvantageTranslate(post, advantageIndex) {
      const currentLangId = (this.current_language === 'ru') ? 0 : 1
      let result = ''
      post.advantages.forEach((advantage, index) => {
        if (index === advantageIndex) {
          result = '<i class="fas fa-bolt me-2"></i>' + advantage.translates[currentLangId].name
        }
      })

      return result
    },
    getMinPrice(cheat) {
      const currentLangId = (this.$i18n.locale === 'ru') ? 0 : 1
      let minPrice = 0;

      cheat.price_plans.forEach((price) => {
        const cost = price.translates[currentLangId]?.cost ?? undefined;
        if (cost && minPrice === 0) {
          minPrice = price.translates[currentLangId].cost;
        }

        if (cost && price.translates[currentLangId].cost < minPrice) {
          minPrice = price.translates[currentLangId].cost;
        }
      })

      if (minPrice === 0) {
        return '<div class="price-mock">0</div>';
      }

      if (currentLangId === 0) {
        return `от ${minPrice} <span>₽</span>`
      }

      return `from <span>$</span>${minPrice}`
    },
    getGame(post) {
        return post?.game?.name
    },
    getTitle(post) {
      return post?.name;
      let translates = this.getTranslates(post)
      if (Array.isArray(translates)) {
        if (translates.length > 1) {
          let ruTranslate = translates[0]
          let enTranslate = translates[1]

          return (this.current_language === 'ru') ? ruTranslate.heading : enTranslate.heading
        }
      }
    },
  }
}
