//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex'

export default {
  name: 'TheGameCategories',
  data() {
    return {
      search: ''
    }
  },
  methods: {
    getUrlToCategory(url_path) {
      let locale = this.$i18n.locale

      return (locale === 'en') ? 'en/' + `cheats/${url_path}` : `cheats/${url_path}`
    },
    imageLoad(event) {
      try {
        const imageHeight = event.path[0].height
        // console.log(event);
      } catch (e) {

      }
    },
    imageError(e) {
      console.error('ee', e);
    }
  },
  computed: {
    ...mapGetters({games: 'getGames'}),
    getSortedGames() {
      let games = Object.assign([], this.games)
      games.sort((a, b) => parseInt(a.position) - parseInt(b.position))
      return games
    },
  },
}
