//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "CheatReview",
  props: {
    iframe_link: String,
    local_link: String
  }
}
