//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PriceChanger from '@/components/cheats/PriceChanger'

export default {
  name: 'CheatActions',
  components: { PriceChanger },
  props: {
    price_plans: Array,
    review_link: String,
    buy_in_crypto_link: String,
    instruction_link: String,
    on_update: Number,
  },
  data () {
    return {
      active_plan_index: 0,
      current_lang: (this.$i18n.locale === 'ru') ? 0 : 1,
      localPlans: [],
    }
  },
  methods: {
    initPricePlans () {
      if (!Array.isArray(this.price_plans)) {
        this.localPlans = []
      } else {
        this.localPlans = this.price_plans
      }
    },
    getCurrentPlan () {
      let activeIndex = this.active_plan_index
      let plansCount = this.getCountOfPlans()

      if (activeIndex >= 0 && activeIndex < plansCount && plansCount > 0) {
        return this.localPlans[activeIndex]
      }

      return null
    },
    getCountOfPlans () {
      let plans = this.localPlans

      if (Array.isArray(plans)) {
        return plans.length
      } else {
        return 0
      }
    },
    getLink () {
      let plan = this.getCurrentPlan()

      if (plan !== null) {
        return plan.translates[this.current_lang].url
      }

      return ''
    },
    isPlansEmpty () {
      return (this.getCountOfPlans() === 0)
    },
    getBuyBtnClass () {
      if (this.isPlansEmpty()) {
        return 'disabled-btn'
      } else {
        return 'buy-btn'
      }
    },
    hasCryptoLink () {
      if (this.$i18n.locale !== 'en') return false
      if (this.buy_in_crypto_link === undefined) return false
      if (this.buy_in_crypto_link.length > 0) return true
    },
    isInstructionExists () {
      if (this.instruction_link) {
        if (this.instruction_link.length > 5) {
          return true
        }
      }

      return false
    },
    checkOnUpdate(e) {
      if(this.on_update) {
        e.preventDefault();
        const text = this.$t('on_update');
        this.$nuxt.$toast.info(text.toString())
      }
    }
  },
  created () {
    this.initPricePlans()
  }
}
