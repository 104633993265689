//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TheCheatPanel from './TheCheatPanel'

export default {
  name: 'TheDescription',
  components: { TheCheatPanel }
}
