import { render, staticRenderFns } from "./CheatReview.vue?vue&type=template&id=189f08ca&scoped=true&"
import script from "./CheatReview.vue?vue&type=script&lang=js&"
export * from "./CheatReview.vue?vue&type=script&lang=js&"
import style0 from "./CheatReview.vue?vue&type=style&index=0&id=189f08ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "189f08ca",
  null
  
)

export default component.exports