//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "CheatFeatures",
  props: {
    feature_categories: Array
  },
  data() {
    return {
      current_lang: (this.$i18n.locale === 'ru') ? 0 : 1,
    }
  },
  methods: {
    getName(category) {
      return category.category_translates[this.current_lang].name;
    },
    getFeatures(category) {
      let result = '';

      category.features.forEach((feature) => {
        result = result + '<li><i class="far fa-check-circle me-2"></i>' + feature.translates[this.current_lang].name + '</li>';
      })

      return result;
    }
  }
}
