//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  name: 'TheReviews',
  computed: mapGetters({ reviews: 'getReviews' })
}
