//
//
//
//
//
//

export default {
  name: "CheatAdditionalInfo",
  props: {
    game_url_path: String,
    url_path: String,
    game_name: String,
  },
  methods: {
    getAdditionalText() {
      if (this.$i18n.locale === 'ru') {
        return 'Ключи, купленные на этой странице, будут работать только в России и других странах СНГ. Если вы хотите приобрести международную версию, то кликните на этот текст Keys purchased on this page will only work in Russia and other CIS countries. If you want to purchase the international version, then click on this text';
      }

      return "Keys purchased on this page won't work in Russia, India and Arab Countries. If you want to purchase the Russian verison, then click on this text. We don't have a version for Arab Countries and India, sorry.";
    },
    getAdditionalPath(url_path, game_url_path) {
      const url = '/cheats/' + game_url_path + '/' + url_path;
      return (this.$i18n.locale === 'ru' ? '/en' + url : url).toLocaleLowerCase();
    }
  }
}
