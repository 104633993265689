//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "PriceChanger",
  props: {
    plans: Array,
    active_plan_index: Number
  },
  data() {
    return {
      current_lang: this.$i18n.locale,
      current_plan: {
        days: 0,
        link: '',
        ru_cost: 0,
        en_cost: 0
      }
    }
  },
  methods: {
    changePlan() {
      let planIndex = Number.parseInt(this.$refs.price_line.value)
      this.current_plan = this.plans[planIndex]
      this.$parent.active_plan_index = planIndex
    },
    initPlan() {
      if (this.plans.length > 0)
        this.current_plan = this.plans[0]

      if(this.$refs.price_line)
        this.$refs.price_line.value = '0'
    },
    getDays() {
      let plan = this.current_plan.translates
      if (plan !== undefined)
        if (this.current_lang === 'ru') {
          return 'Дни: ' + plan[0].days_count
        } else {
          return 'Days: ' + plan[1].days_count
        }

      return ''
    },
    getPrice() {
      let plan = this.current_plan.translates

      if (plan !== undefined)
        if (this.current_lang === 'ru') {
          return plan[0].cost + ' RUB'
        } else {
          return plan[1].cost + ' USD'
        }
    },
    getMax() {
      return (this.plans.length - 1).toString()
    }
  },
  mounted() {
    if (this.plans.length > 0)
      this.initPlan()
  }
}
