//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TheLastNews",
  data() {
    return {
      posts: [],
      current_language: this.$i18n.locale
    }
  },
  async fetch() {
    this.posts = await this.$BlogService.getLastFive()
  },
  methods: {
    getTranslates(post) {
      if (post !== undefined) {
        if (post.hasOwnProperty('translates')) {
          return post.translates
        }
      }

      return []
    },
    getTitle(post) {
      let translates = this.getTranslates(post)
      if (Array.isArray(translates)) {
        if (translates.length > 1) {
          let ruTranslate = translates[0]
          let enTranslate = translates[1]

          return (this.current_language === 'ru') ? ruTranslate.title : enTranslate.title
        }
      }
    },
    getDescription(post) {
      if (this.current_language === 'ru') {
        return post.translates[0].description
      } else {
        return post.translates[1].description
      }
    }
  }
}
