//
//
//
//
//
//
//

export default {
  name: "CheatInfo",
  props: {
    seo_text: String
  }
}
