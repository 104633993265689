//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CheatReview from "@/components/cheats/CheatReview";

export default {
  name: "CheatScreenshots",
  components: {CheatReview},
  props: {
    cheat: Object,
    screenshots: Array,
    iframe_link: String,
    local_link: String
  },
  data() {
    return {
      swiperOptions: {
        zoom: true,
        autoHeight: true,
        slidesPerView: 1,
        spaceBetween: 30,
        loop: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  methods: {
    isVideoContent() {
      if (!this.iframe_link && !this.local_link) {
        return false
      }
      if (this.iframe_link.length > 0) {
        return true
      }
      if (this.local_link.length > 0) {
        return true
      }
    },
    getScreenshotAlt(index) {
      try {
        return this.$t('screenshot') + ' ' + this.cheat.name + '_' + index
      } catch (e) {
        return this.$t('screenshot')
      }
    }
  },
}
